.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #4A5BE4;
}
.ant-image-preview-operations-operation:nth-child(4),
.ant-image-preview-operations-operation:nth-child(5) {
  display: none;
}
.issue-description {
  border: 1px solid white;
  transition: border-color 0.3s ease;
}
.issue-description:hover {
  border: 1px solid #4A5BE4;
}
